<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined class="mt-7 wraperx">
          <v-card-title class="mb-0 pb-0 primary--text">
            FAQs
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="newFAQDialog = true">
              <v-icon left> mdi-plus </v-icon> New FAQ
            </v-btn>
          </v-card-title>
          <v-card-title class="primary--text">
            <v-text-field
              dense
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="faqsHeaders"
            :items="data.data"
            :search.sync="search"
            :items-per-page.sync="limit"
            :page.sync="page"
            :server-items-length="data.totalCount"
            :loading="getFAQsLoadning"
          >
            <template v-slot:item.is_enabled="{ item }">
              <v-btn
                :loading="statusFAQsLoadning && statusFAQsId == item.id"
                @click="updateStatus(item)"
                rounded
                depressed
                x-small
                dark
                :color="item.is_enabled ? 'blue lighten-1' : 'red lighten-1'"
                >{{ item.is_enabled ? "Enabled" : "Disabled" }}</v-btn
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-sheet color="transparent" class="d-flex">
                <v-btn
                  depressed
                  small
                  icon
                  color="primary"
                  class="me-2 px-0 mt-1"
                  dark
                  @click="
                    selectedFAQ = item;
                    editFAQDialog = true;
                  "
                >
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  depressed
                  small
                  icon
                  color="error"
                  class="mx-0 px-0 mt-1"
                  dark
                  @click="
                    selectedFAQ = item;
                    deleteFAQDialog = true;
                  "
                >
                  <v-icon small> mdi-trash-can-outline </v-icon>
                </v-btn>
              </v-sheet>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- New FAQ dialog -->
    <v-dialog v-model="newFAQDialog" persistent max-width="640">
      <v-card outlined>
        <v-card-title class="text-h5"> New FAQ </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="newFAQForm">
            <v-text-field
              flat
              outlined
              label="Question"
              v-model="newFAQ.question"
              :rules="[rules.required]"
            ></v-text-field>
            <v-textarea
              flat
              outlined
              label="Answer"
              v-model="newFAQ.answer"
              :rules="[rules.required]"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="newFAQDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="addFAQX" :loading="newFAQLoading">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit FAQ dialog -->
    <v-dialog
      v-if="selectedFAQ"
      v-model="editFAQDialog"
      persistent
      max-width="640"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Edit FAQ </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="editFAQForm">
            <v-text-field
              flat
              outlined
              label="Question"
              v-model="selectedFAQ.question"
              :rules="[rules.required]"
            ></v-text-field>
            <v-textarea
              flat
              outlined
              label="Answer"
              v-model="selectedFAQ.answer"
              :rules="[rules.required]"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="editFAQDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="editFAQX"
            :loading="editFAQLoading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete faq dialog -->
    <v-dialog
      v-model="deleteFAQDialog"
      persistent
      max-width="290"
      v-if="selectedFAQ"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this faq? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteFAQX(selectedFAQ.id)"
            :loading="deleteFAQLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteFAQDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  getAllFAQs,
  addFAQ,
  editFAQ,
  deleteFAQ,
  disableFAQ,
  enableFAQ,
} from "@/services/faqsService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    search: {
      handler() {
        this.getFAQs();
      },
      deep: true,
    },
    limit: {
      handler() {
        this.getFAQs();
      },
      deep: true,
    },
    page: {
      handler() {
        this.getFAQs();
      },
      deep: true,
    },
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },

    faqsHeaders: [
      {
        text: "Question",
        align: "start",
        value: "question",
        sortable: false,
      },
      {
        text: "Answer",
        value: "answer",
        sortable: false,
      },
      {
        text: "",
        value: "is_enabled",
        sortable: false,
      },
      { text: "", value: "actions", sortable: false },
    ],
    search: "",
    page: 1,
    limit: 10,
    data: [],
    statusFAQsId: null,
    statusFAQsLoadning: false,
    getFAQsLoadning: false,
    editFAQDialog: false,
    editFAQLoading: false,
    newFAQDialog: false,
    newFAQLoading: false,
    deleteFAQDialog: false,
    deleteFAQLoading: false,
    selectedFAQ: null,
    newFAQ: {
      question: "",
      answer: "",
      is_enabled: true,
    },
  }),
  created() {
    this.getFAQs();
  },
  mounted() {
    //
  },
  methods: {
    async getFAQs() {
      this.getFAQsLoadning = true;
      this.data = await getAllFAQs(this.page, this.limit, this.search, -1);
      this.getFAQsLoadning = false;
    },
    async addFAQX() {
      if (this.$refs.newFAQForm.validate()) {
        this.newFAQLoading = true;
        let newFAQReturn = await addFAQ(this.newFAQ);
        this.newFAQLoading = false;

        if (newFAQReturn == "success") {
          this.newFAQDialog = false;

          this.$refs.newFAQForm.reset();
          this.getFAQs();
        }
      }
    },
    async editFAQX() {
      if (this.$refs.editFAQForm.validate()) {
        this.editFAQLoading = true;
        let editFAQReturn = await editFAQ(this.selectedFAQ);
        this.editFAQLoading = false;

        if (editFAQReturn == "success") {
          this.editFAQDialog = false;

          this.$refs.editFAQForm.reset();
          this.getFAQs();
        }
      }
    },
    async deleteFAQX(id) {
      this.deleteFAQLoading = true;
      let deleteFAQReturn = await deleteFAQ({
        id: id,
      });
      this.deleteFAQLoading = false;

      if (deleteFAQReturn == "success") {
        this.deleteFAQDialog = false;
        this.getFAQs();
      }
    },
    async updateStatus(item) {
      this.statusFAQsId = item.id;
      this.statusFAQsLoadning = true;
      let statusFAQReturn;
      if (item.is_enabled) {
        statusFAQReturn = await disableFAQ({
          id: item.id,
        });
      } else {
        statusFAQReturn = await enableFAQ({
          id: item.id,
        });
      }

      this.statusFAQsLoadning = false;

      if (statusFAQReturn == "success") {
        this.getFAQs();
      }
    },
  },
};
</script>