<template>
  <v-container fluid>
    <v-card flat color="transparent" class="pa-sm-4">
      <v-card-title class="primary--text">
        Items
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed @click="newItem()">
          <v-icon left> mdi-plus </v-icon> New Item
        </v-btn>
      </v-card-title>
      <v-card-title class="justify-center justify-sm-start">
        <v-card outlined>
          <v-tabs v-model="tab" @change="getAll" background-color="transparent">
            <v-tab :tab-value="-1">All</v-tab>
            <v-divider vertical></v-divider>
            <v-tab :tab-value="1">Enabled</v-tab>
            <v-divider vertical></v-divider>
            <v-tab :tab-value="0">Disabled</v-tab>
          </v-tabs>
        </v-card>
      </v-card-title>

      <v-card-title class="primary--text">
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              flat
              v-model="categoryIds"
              :items="categories"
              item-text="name"
              item-value="id"
              label="Categories"
              outlined
              dense
              hide-details
              @change="changeCategory"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              flat
              v-model="subCategoryIds"
              :items="subCategories"
              item-text="name"
              item-value="id"
              label="Sub Categories"
              outlined
              dense
              hide-details
              @change="changeSubCategory"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              flat
              v-model="subSubCategoryIds"
              :items="subSubCategories"
              item-text="name"
              item-value="id"
              label="Sub Sub Categories"
              outlined
              dense
              hide-details
              @change="getAll"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              flat
              v-model="filter"
              :items="filters"
              item-text="name"
              item-value="id"
              label="Filter"
              outlined
              dense
              hide-details
              @change="getAll"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-row class="ma-2" v-if="dataLoadingX">
        <v-col cols="12" lg="3" md="4" sm="6" v-for="i in 8" :key="i">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-data-iterator
        v-else
        :items="data.data"
        class="ma-4"
        :search.sync="search"
        :items-per-page.sync="limit"
        :page.sync="page"
        :server-items-length="data.totalCount"
        :loading="loading"
      >
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="(item, i) in props.items"
              :key="i"
              cols="12"
              lg="3"
              md="4"
              sm="6"
            >
              <v-card height="100%" outlined class="d-flex flex-column">
                <v-img
                  height="200"
                  v-if="item.item_images.length > 0"
                  :src="item.item_images[0].image_url"
                  class="text-right"
                >
                  <v-chip
                    dark
                    class="ma-2"
                    :color="item.is_enabled ? 'blue' : 'red'"
                    small
                  >
                    {{ item.is_enabled ? "Enabled" : "Disabled" }}
                  </v-chip>
                </v-img>

                <v-card-title>{{ item.name }}</v-card-title>

                <v-card-text>
                  <div>
                    {{ item.description }}
                  </div>
                </v-card-text>

                <v-spacer></v-spacer>
                <v-card-actions class="mb-auto">
                  <v-chip-group column>
                    <v-chip x-small class="mx-1 text-truncate">
                      {{ getCategory(item.category_id) }}
                    </v-chip>
                    <v-chip
                      x-small
                      class="mx-1 text-truncate"
                      v-if="item.sub_category_id"
                    >
                      {{
                        getSubCategory(item.category_id, item.sub_category_id)
                      }}
                    </v-chip>
                    <v-chip
                      x-small
                      class="mx-1 text-truncate"
                      v-if="item.sub_sub_category_id"
                    >
                      {{
                        getSubSubCategory(
                          item.category_id,
                          item.sub_category_id,
                          item.sub_sub_category_id
                        )
                      }}
                    </v-chip>
                    <v-chip
                      color="purple lighten-1"
                      dark
                      x-small
                      class="mx-1 text-truncate"
                      v-if="item.discount_percent > 0"
                      >Discount {{ item.discount_percent }}%
                    </v-chip>
                    <v-chip
                      color="brown lighten-1"
                      dark
                      x-small
                      class="mx-1 text-truncate"
                      v-if="item.is_featured"
                      >Featured
                    </v-chip>
                    <v-chip
                      color="red lighten-1"
                      dark
                      x-small
                      class="mx-1 text-truncate"
                      v-if="item.is_sold"
                      >Sold
                    </v-chip>
                  </v-chip-group>
                </v-card-actions>

                <v-spacer></v-spacer>
                <v-card-actions class="mb-auto">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" depressed @click="viewItem(item)">
                    Manage
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { getAllItems } from "@/services/itemsService";
import { getCategoriesData } from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    search: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    limit: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    page: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
  },
  data: () => ({
    dataLoadingX: false,
    dataLoading: false,
    loading: false,
    search: "",
    page: 1,
    limit: 10,
    data: [],
    tab: 1,
    filter: 0,
    categoryIds: [],
    subCategoryIds: [],
    subSubCategoryIds: [],
    categories: [],
    subCategories: [],
    subSubCategories: [],
    filters: [
      { id: 0, name: "All" },
      { id: 1, name: "Featured" },
      { id: 2, name: "Discounts" },
      { id: 3, name: "Hot Deals" },
      { id: 4, name: "Sold" },
    ],
  }),
  async created() {
    this.dataLoadingX = true;
    await this.getData();
    if (this.$route.query.cid) {
      this.categoryIds = [Number(this.$route.query.cid)];
    }
    if (this.$route.query.scid) {
      this.subCategoryIds = [Number(this.$route.query.scid)];
    }
    if (this.$route.query.sscid) {
      this.subSubCategoryIds = [Number(this.$route.query.sscid)];
    }
    await this.changeCategory();
    await this.changeSubCategory();
    this.dataLoadingX = false;
  },
  mounted() {
    //
  },
  methods: {
    async getAll() {
      this.loading = true;
      this.data = await getAllItems(
        JSON.stringify(this.categoryIds),
        JSON.stringify(this.subCategoryIds),
        JSON.stringify(this.subSubCategoryIds),
        this.page,
        this.limit,
        this.search,
        this.tab,
        this.filter == 1 ? 1 : -1,
        this.filter == 2 ? 1 : -1,
        this.filter == 3 ? 1 : -1,
        this.filter == 4 ? 1 : -1
      );
      this.loading = false;
    },
    async getData() {
      this.dataLoading = true;
      this.categories = await getCategoriesData();
      this.dataLoading = false;
    },
    viewItem(item) {
      this.$router.push({
        name: "PageViewItem",
        params: { id: item.id },
        query: {
          bc: item.name,
        },
      });
    },
    newItem() {
      this.$router.push({ name: "PageNewItem" });
    },
    async changeCategory() {
      this.subCategories = this.categories
        .filter((element) => this.categoryIds.includes(element.id))
        .flatMap((obj) => obj.sub_categories);

      await this.getAll();
    },
    async changeSubCategory() {
      this.subSubCategories = this.subCategories
        .filter((element) => this.subCategoryIds.includes(element.id))
        .flatMap((obj) => obj.sub_sub_categories);

      await this.getAll();
    },

    getCategory(cid) {
      return this.categories.find((element) => element.id == cid).name;
    },
    getSubCategory(cid, scid) {
      return this.categories
        .find((element) => element.id == cid)
        .sub_categories.find((element) => element.id == scid).name;
    },
    getSubSubCategory(cid, scid, sscid) {
      return this.categories
        .find((element) => element.id == cid)
        .sub_categories.find((element) => element.id == scid)
        .sub_sub_categories.find((element) => element.id == sscid).name;
    },
  },
};
</script>