<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined class="mt-7 wraperx">
          <v-card-title class="mb-0 pb-0 primary--text">
            Advertisements
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              @click="newAdvertisementDialog = true"
            >
              <v-icon left> mdi-plus </v-icon> New Add
            </v-btn>
          </v-card-title>
          
          <v-row class="ma-2" v-if="dataLoadning">
            <v-col cols="12" lg="3" md="4" sm="6" v-for="i in 8" :key="i">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>

          <v-data-iterator
            v-else
            :items="data.data"
            class="ma-4"
            :search.sync="search"
            :items-per-page.sync="limit"
            :page.sync="page"
            :server-items-length="data.totalCount"
            :loading="getAdvertisementsLoadning"
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="(item, i) in props.items"
                  :key="i"
                  cols="12"
                  lg="3"
                  md="4"
                  sm="6"
                >
                  <v-card height="100%" outlined class="d-flex flex-column">
                    <v-img height="200" :src="item.image_url"> </v-img>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="
                          statusAdvertisementsLoadning &&
                          statusAdvertisementsId == item.id
                        "
                        @click="updateStatus(item)"
                        rounded
                        depressed
                        x-small
                        dark
                        class="me-2"
                        :color="
                          item.is_enabled ? 'blue lighten-1' : 'red lighten-1'
                        "
                        >{{ item.is_enabled ? "Enabled" : "Disabled" }}</v-btn
                      >
                      <!-- <v-btn
                  depressed
                  small
                  icon
                  color="primary"
                  class="me-2 px-0 mt-1"
                  dark
                  @click="
                    imagePreview = item.image_url;
                    selectedAdvertisement = item;
                    editAdvertisementDialog = true;
                  "
                >
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn> -->
                      <v-btn
                        depressed
                        small
                        icon
                        color="error"
                        class="mx-0 px-0 mt-1"
                        dark
                        @click="
                          selectedAdvertisement = item;
                          deleteAdvertisementDialog = true;
                        "
                      >
                        <v-icon small> mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
    </v-row>

    <!-- New Advertisement dialog -->
    <v-dialog v-model="newAdvertisementDialog" persistent max-width="480">
      <v-card outlined>
        <v-card-title class="text-h5"> New Advertisement </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="newAdvertisementForm">
            <v-row>
              <v-col cols="12">
                <v-file-input
                  flat
                  outlined
                  :rules="[rules.required, rules.fileSize]"
                  accept="image/*"
                  prepend-icon="mdi-camera"
                  label="Select an image"
                  @change="onFileChange"
                  v-model="image"
                  show-size
                ></v-file-input>
                <v-img
                  v-if="imagePreview"
                  :src="imagePreview"
                  max-width="100%"
                  contain
                  class="mb-6"
                ></v-img>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="newAdvertisementDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="addAdvertisementX"
            :loading="newAdvertisementLoading"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Advertisement dialog -->
    <v-dialog
      v-if="selectedAdvertisement"
      v-model="editAdvertisementDialog"
      persistent
      max-width="480"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Edit Advertisement </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="editAdvertisementForm">
            <v-row>
              <v-col cols="12">
                <v-file-input
                  flat
                  outlined
                  :rules="[rules.fileSize]"
                  accept="image/*"
                  prepend-icon="mdi-camera"
                  label="Select an image"
                  @change="onFileChange"
                  v-model="image"
                  show-size
                ></v-file-input>
                <v-img
                  v-if="imagePreview"
                  :src="imagePreview"
                  max-width="100%"
                  contain
                  class="mb-6"
                ></v-img>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="editAdvertisementDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="editAdvertisementX"
            :loading="editAdvertisementLoading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete advertisement dialog -->
    <v-dialog
      v-model="deleteAdvertisementDialog"
      persistent
      max-width="290"
      v-if="selectedAdvertisement"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this advertisement? This action cannot
          be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteAdvertisementX(selectedAdvertisement.id)"
            :loading="deleteAdvertisementLoading"
          >
            Delete
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteAdvertisementDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { uploadDocument } from "@/services/uploadDocumentsService";
import {
  getAllAdvertisements,
  addAdvertisement,
  editAdvertisement,
  deleteAdvertisement,
  disableAdvertisement,
  enableAdvertisement,
} from "@/services/advertisementsService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    search: {
      handler() {
        this.getAdvertisements();
      },
      deep: true,
    },
    limit: {
      handler() {
        this.getAdvertisements();
      },
      deep: true,
    },
    page: {
      handler() {
        this.getAdvertisements();
      },
      deep: true,
    },
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
      fileSize: (value) => {
        if (!value) return true;
        return (
          value.size <= 500 * 1024 || "File size must be less than 500 KB."
        );
      },
    },
    search: "",
    page: 1,
    limit: 10,
    data: [],
    dataLoadning: false,
    statusAdvertisementsId: null,
    statusAdvertisementsLoadning: false,
    getAdvertisementsLoadning: false,
    editAdvertisementDialog: false,
    editAdvertisementLoading: false,
    newAdvertisementDialog: false,
    newAdvertisementLoading: false,
    deleteAdvertisementDialog: false,
    deleteAdvertisementLoading: false,
    selectedAdvertisement: null,
    newAdvertisement: {
      image_url: "",
      is_enabled: true,
    },
    image: null,
    imagePreview: null,
  }),
  async created() {
    this.dataLoadning = true;
    await this.getAdvertisements();
    this.dataLoadning = false;
  },
  mounted() {
    //
  },
  methods: {
    async getAdvertisements() {
      this.getAdvertisementsLoadning = true;
      this.data = await getAllAdvertisements(
        this.page,
        this.limit,
        this.search,
        -1
      );
      this.getAdvertisementsLoadning = false;
    },
    async addAdvertisementX() {
      if (this.$refs.newAdvertisementForm.validate()) {
        this.newAdvertisementLoading = true;
        if (this.image) {
          const formData = new FormData();
          formData.append("file", this.image);
          let upladDocumentReturn = await uploadDocument(
            "advertisement",
            formData
          );
          this.newAdvertisement.image_url = upladDocumentReturn.document_url;
        }
        let newAdvertisementReturn = await addAdvertisement(
          this.newAdvertisement
        );
        this.newAdvertisementLoading = false;

        if (newAdvertisementReturn == "success") {
          this.newAdvertisementDialog = false;

          this.$refs.newAdvertisementForm.reset();
          this.getAdvertisements();
        }
      }
    },
    async editAdvertisementX() {
      if (this.$refs.editAdvertisementForm.validate()) {
        this.editAdvertisementLoading = true;
        if (this.image) {
          const formData = new FormData();
          formData.append("file", this.image);
          let upladDocumentReturn = await uploadDocument(
            "advertisement",
            formData
          );
          this.selectedAdvertisement.image_url =
            upladDocumentReturn.document_url;
        }
        let editAdvertisementReturn = await editAdvertisement(
          this.selectedAdvertisement
        );
        this.editAdvertisementLoading = false;

        if (editAdvertisementReturn == "success") {
          this.editAdvertisementDialog = false;

          this.$refs.editAdvertisementForm.reset();
          this.getAdvertisements();
        }
      }
    },
    async deleteAdvertisementX(id) {
      this.deleteAdvertisementLoading = true;
      let deleteAdvertisementReturn = await deleteAdvertisement({
        id: id,
      });
      this.deleteAdvertisementLoading = false;

      if (deleteAdvertisementReturn == "success") {
        this.deleteAdvertisementDialog = false;
        this.getAdvertisements();
      }
    },
    async updateStatus(item) {
      this.statusAdvertisementsId = item.id;
      this.statusAdvertisementsLoadning = true;
      let statusAdvertisementReturn;
      if (item.is_enabled) {
        statusAdvertisementReturn = await disableAdvertisement({
          id: item.id,
        });
      } else {
        statusAdvertisementReturn = await enableAdvertisement({
          id: item.id,
        });
      }

      this.statusAdvertisementsLoadning = false;

      if (statusAdvertisementReturn == "success") {
        this.getAdvertisements();
      }
    },
    onFileChange(file) {
      this.selectedFile = file;
      if (file) {
        // Create a temporary URL to show the image preview
        this.imagePreview = URL.createObjectURL(file);
      } else {
        this.imagePreview = null;
      }
    },
  },
};
</script>