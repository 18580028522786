import store from "../store";
import axiosInstance from "../axiosInstance";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/admin";
const STORE_NAME = "enquiries";

export const getAllEnquiries = async (page, limit, search, isEnabled) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/emails?page=${page}&limit=${limit}&search=${search}&is_enabled=${isEnabled}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};

export const getEnquiry = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/emails/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};

export const deleteEnquiry = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/emails/delete`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to delete ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to delete ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};

export const readEnquiry = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/emails/mark-as-read`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to enable ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to enable ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};
