<template>
  <v-container fluid>
    <v-card flat color="transparent" class="pa-sm-4">
      <v-card-title class="primary--text">
        Sub Categories
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed @click="newItem()">
          <v-icon left> mdi-plus </v-icon> New Sub Category
        </v-btn>
      </v-card-title>
      <v-card-title class="justify-center justify-sm-start">
        <v-card outlined>
          <v-tabs v-model="tab" @change="getAll" background-color="transparent">
            <v-tab :tab-value="-1">All</v-tab>
            <v-divider vertical></v-divider>
            <v-tab :tab-value="1">Enabled</v-tab>
            <v-divider vertical></v-divider>
            <v-tab :tab-value="0">Disabled</v-tab>
          </v-tabs>
        </v-card>
      </v-card-title>

      <v-card-title class="primary--text">
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              flat
              v-model="categoryIds"
              :items="categories"
              item-text="name"
              item-value="id"
              label="Main Categories"
              outlined
              dense
              hide-details
              @change="getAll"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-row class="ma-2" v-if="dataLoadingX">
        <v-col cols="12" lg="3" md="4" sm="6" v-for="i in 8" :key="i">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-data-iterator
        v-else
        :items="data.data"
        class="ma-4"
        :search.sync="search"
        :items-per-page.sync="limit"
        :page.sync="page"
        :server-items-length="data.totalCount"
        :loading="loading"
      >
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="(item, i) in props.items"
              :key="i"
              cols="12"
              lg="3"
              md="4"
              sm="6"
            >
              <v-card height="100%" outlined class="d-flex flex-column">
                <v-img height="200" :src="item.image" class="text-right">
                  <v-chip
                    dark
                    class="ma-2"
                    :color="item.is_enabled ? 'blue' : 'red'"
                    small
                  >
                    {{ item.is_enabled ? "Enabled" : "Disabled" }}
                  </v-chip>
                </v-img>

                <v-card-title>{{ item.name }}</v-card-title>

                <v-card-text>
                  <div>
                    {{ item.description }}
                  </div>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="mb-auto">
                  <v-chip-group column>
                    <v-chip x-small>
                      {{ getCategory(item.category_id) }}
                    </v-chip>
                  </v-chip-group>
                </v-card-actions>
                <v-spacer></v-spacer>
                <v-card-actions class="mb-auto">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" depressed @click="viewItem(item)">
                    Manage
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { getAllSubCategories } from "@/services/subCategoriesService";
import { getCategoriesData } from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    search: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    limit: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    page: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
  },
  data: () => ({
    dataLoadingX: false,
    dataLoading: false,
    loading: false,
    search: "",
    page: 1,
    limit: 10,
    data: [],
    tab: 1,
    categoryIds: [],
    categories: [],
  }),
  async created() {
    this.dataLoadingX = true;
    await this.getData();
    if (this.$route.query.cid) {
      this.categoryIds = [Number(this.$route.query.cid)];
    }
    await this.getAll();
    this.dataLoadingX = false;
  },
  mounted() {
    //
  },
  methods: {
    async getAll() {
      this.loading = true;
      this.data = await getAllSubCategories(
        JSON.stringify(this.categoryIds),
        this.page,
        this.limit,
        this.search,
        this.tab
      );
      this.loading = false;
    },
    async getData() {
      this.dataLoading = true;
      this.categories = await getCategoriesData();
      this.dataLoading = false;
    },
    viewItem(item) {
      this.$router.push({
        name: "PageViewSubCategory",
        params: { id: item.id },
        query: { bc: item.name },
      });
    },
    newItem() {
      this.$router.push({ name: "PageNewSubCategory" });
    },
    getCategory(id) {
      return this.categories.find((element) => element.id == id).name;
    },
  },
};
</script>