<template>
  <v-container fluid>
    <v-card flat color="transparent" class="pa-sm-4">
      <v-card-title class="primary--text">
        Main Categories
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed @click="newItem()">
          <v-icon left> mdi-plus </v-icon> New Main Category
        </v-btn>
      </v-card-title>
      <v-card-title class="justify-center justify-sm-start">
        <v-card outlined>
          <v-tabs v-model="tab" @change="getAll" background-color="transparent">
            <v-tab :tab-value="-1">All</v-tab>
            <v-divider vertical></v-divider>
            <v-tab :tab-value="1">Enabled</v-tab>
            <v-divider vertical></v-divider>
            <v-tab :tab-value="0">Disabled</v-tab>
          </v-tabs>
        </v-card>
      </v-card-title>

      <v-card-title class="primary--text">
        <v-text-field
          outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-card-title>

      <v-row class="ma-2" v-if="dataLoading">
        <v-col cols="12" lg="3" md="4" sm="6" v-for="i in 8" :key="i">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-data-iterator
        v-else
        :items="data.data"
        class="ma-4"
        :search.sync="search"
        :items-per-page.sync="limit"
        :page.sync="page"
        :server-items-length="data.totalCount"
        :loading="loading"
      >
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="(item, i) in props.items"
              :key="i"
              cols="12"
              lg="3"
              md="4"
              sm="6"
            >
              <v-card height="100%" outlined class="d-flex flex-column">
                <v-img height="200" :src="item.image" class="text-right">
                  <v-chip
                    dark
                    class="ma-2"
                    :color="item.is_enabled ? 'blue' : 'red'"
                    small
                  >
                    {{ item.is_enabled ? "Enabled" : "Disabled" }}
                  </v-chip>
                </v-img>

                <v-card-title>{{ item.name }}</v-card-title>

                <v-card-text>
                  <div>
                    {{ item.description }}
                  </div>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" depressed @click="viewItem(item)">
                    Manage
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { getAllCategories } from "@/services/categoriesService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    search: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    limit: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    page: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
  },
  data: () => ({
    dataLoading: false,
    loading: false,
    search: "",
    page: 1,
    limit: 10,
    data: [],
    tab: 1,
  }),
  async created() {
    this.dataLoading = true;
    await this.getAll();
    this.dataLoading = false;
  },
  mounted() {
    //
  },
  methods: {
    async getAll() {
      this.loading = true;
      this.data = await getAllCategories(
        this.page,
        this.limit,
        this.search,
        this.tab
      );
      this.loading = false;
    },
    viewItem(item) {
      this.$router.push({
        name: "PageViewCategory",
        params: { id: item.id },
        query: { bc: item.name },
      });
    },
    newItem() {
      this.$router.push({ name: "PageNewCategory" });
    },
  },
};
</script>