<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined class="mt-7 wraperx">
          <v-card-title class="mb-0 pb-0 primary--text">
            Why
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="newWhyDialog = true">
              <v-icon left> mdi-plus </v-icon> New Why
            </v-btn>
          </v-card-title>
          <v-card-title class="primary--text">
            <v-text-field
              dense
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="whyHeaders"
            :items="data.data"
            :search.sync="search"
            :items-per-page.sync="limit"
            :page.sync="page"
            :server-items-length="data.totalCount"
            :loading="getWhyLoadning"
          >
          <template v-slot:item.mdi_icon="{ item }">
              <v-icon x-large class="my-4">
                mdi-{{ item.mdi_icon }}
              </v-icon>
            </template>
            <template v-slot:item.is_enabled="{ item }">
              <v-btn
                :loading="statusWhyLoadning && statusWhyId == item.id"
                @click="updateStatus(item)"
                rounded
                depressed
                x-small
                dark
                :color="item.is_enabled ? 'blue lighten-1' : 'red lighten-1'"
                >{{ item.is_enabled ? "Enabled" : "Disabled" }}</v-btn
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-sheet color="transparent" class="d-flex">
                <v-btn
                  depressed
                  small
                  icon
                  color="primary"
                  class="me-2 px-0 mt-1"
                  dark
                  @click="
                    selectedWhy = item;
                    editWhyDialog = true;
                  "
                >
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  depressed
                  small
                  icon
                  color="error"
                  class="mx-0 px-0 mt-1"
                  dark
                  @click="
                    selectedWhy = item;
                    deleteWhyDialog = true;
                  "
                >
                  <v-icon small> mdi-trash-can-outline </v-icon>
                </v-btn>
              </v-sheet>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- New Why dialog -->
    <v-dialog v-model="newWhyDialog" persistent max-width="640">
      <v-card outlined>
        <v-card-title class="text-h5"> New Why </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="newWhyForm">
            <v-text-field
              flat
              outlined
              label="Title"
              v-model="newWhy.title"
              :rules="[rules.required]"
            ></v-text-field>
            <v-textarea
              flat
              outlined
              label="Description"
              v-model="newWhy.description"
              :rules="[rules.required]"
            ></v-textarea>
            <v-text-field
              flat
              outlined
              label="MDI Icon"
              v-model="newWhy.mdi_icon"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="newWhyDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="addWhyX" :loading="newWhyLoading">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Why dialog -->
    <v-dialog
      v-if="selectedWhy"
      v-model="editWhyDialog"
      persistent
      max-width="640"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Edit Why </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="editWhyForm">
            <v-text-field
              flat
              outlined
              label="Title"
              v-model="selectedWhy.title"
              :rules="[rules.required]"
            ></v-text-field>
            <v-textarea
              flat
              outlined
              label="Description"
              v-model="selectedWhy.description"
              :rules="[rules.required]"
            ></v-textarea>
            <v-text-field
              flat
              outlined
              label="MDI Icon"
              v-model="selectedWhy.mdi_icon"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="editWhyDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="editWhyX"
            :loading="editWhyLoading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete why dialog -->
    <v-dialog
      v-model="deleteWhyDialog"
      persistent
      max-width="290"
      v-if="selectedWhy"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this why? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteWhyX(selectedWhy.id)"
            :loading="deleteWhyLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteWhyDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  getAllWhy,
  addWhy,
  editWhy,
  deleteWhy,
  disableWhy,
  enableWhy,
} from "@/services/whyService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    search: {
      handler() {
        this.getWhy();
      },
      deep: true,
    },
    limit: {
      handler() {
        this.getWhy();
      },
      deep: true,
    },
    page: {
      handler() {
        this.getWhy();
      },
      deep: true,
    },
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },

    whyHeaders: [
      {
        text: "Icon",
        align: "start",
        value: "mdi_icon",
        sortable: false,
      },
      {
        text: "Title",
        value: "title",
        sortable: false,
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
      },
      {
        text: "",
        value: "is_enabled",
        sortable: false,
      },
      { text: "", value: "actions", sortable: false },
    ],
    search: "",
    page: 1,
    limit: 10,
    data: [],
    statusWhyId: null,
    statusWhyLoadning: false,
    getWhyLoadning: false,
    editWhyDialog: false,
    editWhyLoading: false,
    newWhyDialog: false,
    newWhyLoading: false,
    deleteWhyDialog: false,
    deleteWhyLoading: false,
    selectedWhy: null,
    newWhy: {
      title: "",
      description: "",
      icon_url: "",
      mdi_icon: "",
      is_enabled: true,
    },
  }),
  created() {
    this.getWhy();
  },
  mounted() {
    //
  },
  methods: {
    async getWhy() {
      this.getWhyLoadning = true;
      this.data = await getAllWhy(this.page, this.limit, this.search, -1);
      this.getWhyLoadning = false;
    },
    async addWhyX() {
      if (this.$refs.newWhyForm.validate()) {
        this.newWhyLoading = true;
        let newWhyReturn = await addWhy(this.newWhy);
        this.newWhyLoading = false;

        if (newWhyReturn == "success") {
          this.newWhyDialog = false;

          this.$refs.newWhyForm.reset();
          this.getWhy();
        }
      }
    },
    async editWhyX() {
      if (this.$refs.editWhyForm.validate()) {
        this.editWhyLoading = true;
        let editWhyReturn = await editWhy(this.selectedWhy);
        this.editWhyLoading = false;

        if (editWhyReturn == "success") {
          this.editWhyDialog = false;

          this.$refs.editWhyForm.reset();
          this.getWhy();
        }
      }
    },
    async deleteWhyX(id) {
      this.deleteWhyLoading = true;
      let deleteWhyReturn = await deleteWhy({
        id: id,
      });
      this.deleteWhyLoading = false;

      if (deleteWhyReturn == "success") {
        this.deleteWhyDialog = false;
        this.getWhy();
      }
    },
    async updateStatus(item) {
      this.statusWhyId = item.id;
      this.statusWhyLoadning = true;
      let statusWhyReturn;
      if (item.is_enabled) {
        statusWhyReturn = await disableWhy({
          id: item.id,
        });
      } else {
        statusWhyReturn = await enableWhy({
          id: item.id,
        });
      }

      this.statusWhyLoadning = false;

      if (statusWhyReturn == "success") {
        this.getWhy();
      }
    },
  },
};
</script>