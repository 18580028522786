<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined class="mt-7 wraperx">
          <v-card-title class="mb-0 pb-0 primary--text">
            Who
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" depressed @click="newWhoDialog = true">
              <v-icon left> mdi-plus </v-icon> New Who
            </v-btn> -->
          </v-card-title>
          <v-card-title class="primary--text">
            <v-text-field
              dense
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="whoHeaders"
            :items="data.data"
            :search.sync="search"
            :items-per-page.sync="limit"
            :page.sync="page"
            :server-items-length="data.totalCount"
            :loading="getWhoLoadning"
          >
            <template v-slot:item.is_enabled="{ item }">
              <v-btn
                :loading="statusWhoLoadning && statusWhoId == item.id"
                @click="updateStatus(item)"
                rounded
                depressed
                x-small
                dark
                :color="item.is_enabled ? 'blue lighten-1' : 'red lighten-1'"
                >{{ item.is_enabled ? "Enabled" : "Disabled" }}</v-btn
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-sheet color="transparent" class="d-flex">
                <v-btn
                  depressed
                  small
                  icon
                  color="primary"
                  class="me-2 px-0 mt-1"
                  dark
                  @click="
                    selectedWho = item;
                    editWhoDialog = true;
                  "
                >
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
                <!-- <v-btn
                  depressed
                  small
                  icon
                  color="error"
                  class="mx-0 px-0 mt-1"
                  dark
                  @click="
                    selectedWho = item;
                    deleteWhoDialog = true;
                  "
                >
                  <v-icon small> mdi-trash-can-outline </v-icon>
                </v-btn> -->
              </v-sheet>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- New Who dialog -->
    <v-dialog v-model="newWhoDialog" persistent max-width="640">
      <v-card outlined>
        <v-card-title class="text-h5"> New Who </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="newWhoForm">
            <v-text-field
              flat
              outlined
              label="Key"
              v-model="newWho.key"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              flat
              outlined
              label="Value"
              v-model="newWho.value"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="newWhoDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="addWhoX" :loading="newWhoLoading">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Who dialog -->
    <v-dialog
      v-if="selectedWho"
      v-model="editWhoDialog"
      persistent
      max-width="640"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Edit Who </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="editWhoForm">
            <v-text-field
              flat
              outlined
              label="Key"
              v-model="selectedWho.key"
              :rules="[rules.required]"
              readonly
            ></v-text-field>
            <v-text-field
              flat
              outlined
              label="Value"
              v-model="selectedWho.value"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="editWhoDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="editWhoX"
            :loading="editWhoLoading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete who dialog -->
    <v-dialog
      v-model="deleteWhoDialog"
      persistent
      max-width="290"
      v-if="selectedWho"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this who? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteWhoX(selectedWho.id)"
            :loading="deleteWhoLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteWhoDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  getAllWho,
  addWho,
  editWho,
  deleteWho,
  disableWho,
  enableWho,
} from "@/services/whoService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    search: {
      handler() {
        this.getWho();
      },
      deep: true,
    },
    limit: {
      handler() {
        this.getWho();
      },
      deep: true,
    },
    page: {
      handler() {
        this.getWho();
      },
      deep: true,
    },
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },

    whoHeaders: [
      {
        text: "Key",
        align: "start",
        value: "key",
        sortable: false,
      },
      {
        text: "Value",
        value: "value",
        sortable: false,
      },
      {
        text: "Status",
        value: "is_enabled",
        sortable: false,
      },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    search: "",
    page: 1,
    limit: 10,
    data: [],
    statusWhoId: null,
    statusWhoLoadning: false,
    getWhoLoadning: false,
    editWhoDialog: false,
    editWhoLoading: false,
    newWhoDialog: false,
    newWhoLoading: false,
    deleteWhoDialog: false,
    deleteWhoLoading: false,
    selectedWho: null,
    newWho: {
      key: "",
      value: "",
      is_enabled: true,
    },
  }),
  created() {
    this.getWho();
  },
  mounted() {
    //
  },
  methods: {
    async getWho() {
      this.getWhoLoadning = true;
      this.data = await getAllWho(this.page, this.limit, this.search, -1);
      this.getWhoLoadning = false;
    },
    async addWhoX() {
      if (this.$refs.newWhoForm.validate()) {
        this.newWhoLoading = true;
        let newWhoReturn = await addWho(this.newWho);
        this.newWhoLoading = false;

        if (newWhoReturn == "success") {
          this.newWhoDialog = false;

          this.$refs.newWhoForm.reset();
          this.getWho();
        }
      }
    },
    async editWhoX() {
      if (this.$refs.editWhoForm.validate()) {
        this.editWhoLoading = true;
        let editWhoReturn = await editWho(this.selectedWho);
        this.editWhoLoading = false;

        if (editWhoReturn == "success") {
          this.editWhoDialog = false;

          this.$refs.editWhoForm.reset();
          this.getWho();
        }
      }
    },
    async deleteWhoX(id) {
      this.deleteWhoLoading = true;
      let deleteWhoReturn = await deleteWho({
        id: id,
      });
      this.deleteWhoLoading = false;

      if (deleteWhoReturn == "success") {
        this.deleteWhoDialog = false;
        this.getWho();
      }
    },
    async updateStatus(item) {
      this.statusWhoId = item.id;
      this.statusWhoLoadning = true;
      let statusWhoReturn;
      if (item.is_enabled) {
        statusWhoReturn = await disableWho({
          id: item.id,
        });
      } else {
        statusWhoReturn = await enableWho({
          id: item.id,
        });
      }

      this.statusWhoLoadning = false;

      if (statusWhoReturn == "success") {
        this.getWho();
      }
    },
  },
};
</script>