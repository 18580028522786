import store from "../store";
import axiosInstance from "../axiosInstance";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/admin";
const STORE_NAME = "items";

export const getAllItems = async (
  categoryIds,
  subCategoryIds,
  subSubCategoryIds,
  page,
  limit,
  search,
  isEnabled,
  isFeatured,
  isDiscount,
  isHotdeal,
  isSold,
) => {
  if (navigator.onLine) {
    const url =
      API_URL +
      `/v1/items/all?page=${page}&limit=${limit}&search=${search}&is_enabled=${isEnabled}&is_featured=${isFeatured}&is_discount=${isDiscount}&is_hotdeal=${isHotdeal}&is_sold=${isSold}&category_ids=${categoryIds}&sub_category_ids=${subCategoryIds}&sub_sub_category_ids=${subSubCategoryIds}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};

export const getItem = async (id) => {
  if (navigator.onLine) {
    const url =
      API_URL +
      `/v1/items/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};

export const addItem = async (data) => {
  if (navigator.onLine) {
    const url =
      API_URL +
      `/v1/items/new`;
    const params = data;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to create ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to create ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};

export const editItem = async (data) => {
  if (navigator.onLine) {
    const url =
      API_URL +
      `/v1/items/edit`;
    const params = data;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to edit ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to edit ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};

export const deleteItem = async (data) => {
  if (navigator.onLine) {
    const url =
      API_URL +
      `/v1/items/delete`;
    const params = data;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to delete ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to delete ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};

export const enableItem = async (data) => {
  if (navigator.onLine) {
    const url =
      API_URL +
      `/v1/items/enable`;
    const params = data;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to enable ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to enable ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};

export const disableItem = async (data) => {
  if (navigator.onLine) {
    const url =
      API_URL +
      `/v1/items/disable`;
    const params = data;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to disable ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to disable ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};
