<template>
  <v-container fluid>
    <v-row>
          <v-col cols="12">
            <v-card class="pa-2 name-card" flat>
              <div class="text-h6">👋 Hello {{ auth.display_name }}!</div>

              <div class="px-5 py-3">
                Welcome to the <span>dealPartner.lk</span> Admin
          Dashboard! Here, you can manage deals, monitor performance, and
          optimize our platform. Use the tools at your disposal to keep
          everything running smoothly and ensure the best experience for our
          users. Your work is essential in maintaining the quality and success
          of <span>dealPartner.lk</span>.
              </div>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-row  justify="center">
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card "
                  color="green lighten-2"
                  flat
                >
                <v-icon class="aution-icon">mdi-account-supervisor-circle</v-icon>
                  <div class="text-h3 text-center pt-5">{{ counts.total_users }}</div>
                  <div class="text-center text-caption pb-3">Admin Users</div>
                </v-card>
              </v-col>

              <v-col cols="6" sm="4" md="3" lg="2">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card"
                  color="yellow"
                  flat
                >
                <v-icon class="aution-icon">mdi-comment-text </v-icon>
                  <div class="text-h3 text-center pt-5">{{ counts.total_reviews }}</div>
                  <div class="text-center text-caption pb-3">User Reviews</div>
                </v-card>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card "
                  color="blue lighten-2"
                  flat
                >
                <v-icon class="aution-icon">mdi-shape </v-icon>
                  <div class="text-h3 text-center pt-5">{{ counts.total_categories }}</div>
                  <div class="text-center text-caption pb-3">Categories</div>
                </v-card>
              </v-col>

              <v-col cols="6" sm="4" md="3" lg="2">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card"
                  color="purple lighten-2"
                  flat
                >
                <v-icon class="aution-icon">mdi-shape </v-icon>
                  <div class="text-h3 text-center pt-5">{{ counts.total_sub_categories }}</div>
                  <div class="text-center text-caption pb-3">Sub Categories</div>
                </v-card>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card "
                  color="pink lighten-2"
                  flat
                >
                <v-icon class="aution-icon">mdi-shape </v-icon>
                  <div class="text-h3 text-center pt-5">{{ counts.total_sub_sub_categories }}</div>
                  <div class="text-center text-caption pb-3">Sub Sub Categories</div>
                </v-card>
              </v-col>

              <v-col  cols="6" sm="4" md="3" lg="2">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card"
                  color="indigo"
                  flat
                >
                <v-icon class="aution-icon">mdi-shape-plus </v-icon>
                  <div class="text-h3 text-center pt-5">{{ counts.total_items }}</div>
                  <div class="text-center text-caption pb-3">Total Items</div>
                </v-card>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card "
                  color="red lighten-2"
                  flat
                >
                <v-icon class="aution-icon">mdi-star </v-icon>
                  <div class="text-h3 text-center pt-5">{{ counts.featured_items }}</div>
                  <div class="text-center text-caption pb-3">Featured Items</div>
                </v-card>
              </v-col>

              <v-col cols="6" sm="4" md="3" lg="2">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card"
                  color="red lighten-6"
                  flat
                >
                <v-icon class="aution-icon">mdi-fire </v-icon>
                  <div class="text-h3 text-center pt-5">{{ counts.hot_deal_items }}</div>
                  <div class="text-center text-caption pb-3">Hot Deals</div>
                </v-card>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card "
                  color="pink lighten-6"
                  flat
                >
                <v-icon class="aution-icon">mdi-tag </v-icon>
                  <div class="text-h3 text-center pt-5">{{ counts.discount_items }}</div>
                  <div class="text-center text-caption pb-3">Discount Items</div>
                </v-card>
              </v-col>

              <v-col cols="6" sm="4" md="3" lg="2">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card"
                  color="grey"
                  flat
                >
                <v-icon class="aution-icon">mdi-chart-timeline-variant-shimmer </v-icon>
                  <div class="text-h3 text-center pt-5">{{ counts.sold_items }}</div>
                  <div class="text-center text-caption pb-3">Sold Items</div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getCounts } from "@/services/dashboardService";
export default {
  components: {
    // Calendar: () =>
    //   import(
    //     /* webpackChunkName: "component-calendar" */ "@/components/Calendar"
    //   ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    counts: []
  }),
  async created() {
    this.dataLoading = true;
      this.counts = await getCounts();
      console.log(this.counts)
      this.dataLoading = false;
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>

<style>
.name-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.8) 0%,
    rgba(12, 186, 189, 0.8) 100%
  );
  color: white !important;
}

.user-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.5) 0%,
    rgba(12, 186, 189, 0.5) 100%
  );
}

.aution-icon{
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 50px !important;
  position: absolute !important;
}
</style>