<template>
  <v-container fluid>
    <v-card flat color="transparent" class="pa-sm-4">
      <v-card-title class="primary--text">
        New Sub Sub Category
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="mt-6">
        <v-form ref="newForm" @submit.prevent>
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-file-input
                v-else
                flat
                outlined
                :rules="[rules.required, rules.fileSize]"
                accept="image/*"
                prepend-icon="mdi-camera"
                label="Select an image"
                @change="onFileChange"
                v-model="image"
                show-size
              ></v-file-input>
              <v-img
                v-if="imagePreview"
                :src="imagePreview"
                max-width="100%"
                contain
              ></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-autocomplete
                v-else
                flat
                v-model="item.category_id"
                :items="categories"
                item-text="name"
                item-value="id"
                label="Select Main Category"
                outlined
                :rules="[rules.required]"
                @change="changeCategory"
              ></v-autocomplete>
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-autocomplete
                v-else
                flat
                v-model="item.sub_category_id"
                :items="subCategories"
                item-text="name"
                item-value="id"
                label="Select Sub Category"
                outlined
                :rules="[rules.required]"
              ></v-autocomplete>
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                flat
                outlined
                label="Sub Sub Category Name"
                v-model="item.name"
                :rules="[rules.required]"
              ></v-text-field>
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-textarea
                v-else
                flat
                outlined
                label="Description"
                v-model="item.description"
                :rules="[rules.required]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-title v-if="!dataLoading">
        <v-spacer></v-spacer>
        <v-btn color="secondary" depressed @click="goBack" text class="mx-3">
          cancel
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="createSubSubCategory"
          :loading="loading"
        >
          Create
        </v-btn>
      </v-card-title>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { addSubSubCategory } from "@/services/subSubCategoriesService";
import { uploadDocument } from "@/services/uploadDocumentsService";
import { getCategoriesData } from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    dataLoading: false,
    rules: {
      required: (value) => !!value || "Required.",
      fileSize: (value) => {
        if (!value) return true;
        return (
          value.size <= 500 * 1024 || "File size must be less than 500 KB."
        );
      },
    },
    item: {
      name: "",
      image: "",
      description: "",
      is_enabled: true,
      category_id: null,
      sub_category_id: null
    },
    image: null,
    imagePreview: null,
    categories: [],
    subCategories: [],
  }),
  async created() {
    this.getData();
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "PageSubSubCategoryList",
        query: {  cid: this.item.category_id, scid: this.item.sub_category_id },
      });
    },
    async getData() {
      this.dataLoading = true;
      this.categories = await getCategoriesData();
      this.dataLoading = false;
    },
    async createSubSubCategory() {
      if (this.$refs.newForm.validate()) {
        this.loading = true;
        if (this.image) {
          const formData = new FormData();
          formData.append("file", this.image);
          let upladDocumentReturn = await uploadDocument(
            "subSubCategory",
            formData
          );
          this.item.image = upladDocumentReturn.document_url;
        }
        let addSubSubCategoryReturn = await addSubSubCategory(
          this.item
        );
        this.loading = false;
        if (addSubSubCategoryReturn == "success") {
          this.goBack();
        }
      }
    },
    onFileChange(file) {
      this.selectedFile = file;
      if (file) {
        // Create a temporary URL to show the image preview
        this.imagePreview = URL.createObjectURL(file);
      } else {
        this.imagePreview = null;
      }
    },
    changeCategory() {
      this.subCategories = this.categories.find(
        (element) => element.id == this.item.category_id
      ).sub_categories;
    },
  },
};
</script>