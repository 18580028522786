<template>
  <v-container fluid>
    <v-card flat color="transparent" class="pa-sm-4 wraperx">
      <v-card-title class="primary--text">
        <v-btn icon class="me-2" @click="goBack">
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        View Sub Sub Category
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
            <v-img
              v-else
              :src="item.image"
              max-width="100%"
              :aspect-ratio="16/10"
              class="text-right"
            >
              <v-chip
                dark
                class="ma-2"
                :color="item.is_enabled ? 'blue' : 'red'"
                small
              >
                {{ item.is_enabled ? "Enabled" : "Disabled" }}
              </v-chip>
            </v-img>
          </v-col>
          <v-col cols="12" md="6">
            <v-sheet class="mb-4" color="transparent">
              <v-sheet class="text-caption" color="transparent"
                >Main Category name</v-sheet
              >
              <v-skeleton-loader
                type="heading"
                v-if="loading"
              ></v-skeleton-loader>
              <h4 class="text--primary large font-weight-medium" v-else>
                {{ item.categories.name }}
              </h4>
            </v-sheet>
            <v-sheet class="mb-4" color="transparent">
              <v-sheet class="text-caption" color="transparent"
                >Sub Category name</v-sheet
              >
              <v-skeleton-loader
                type="heading"
                v-if="loading"
              ></v-skeleton-loader>
              <h4 class="text--primary large font-weight-medium" v-else>
                {{ item.sub_categories.name }}
              </h4>
            </v-sheet>
            <v-sheet class="mb-4" color="transparent">
              <v-sheet class="text-caption" color="transparent"
                >Sub Sub Category name</v-sheet
              >
              <v-skeleton-loader
                type="heading"
                v-if="loading"
              ></v-skeleton-loader>
              <h2 class="text--primary large font-weight-medium" v-else>
                {{ item.name }}
              </h2>
            </v-sheet>
            <v-sheet class="mb-4" max-width="480" color="transparent">
              <v-sheet class="text-caption" color="transparent"
                >Description</v-sheet
              >
              <v-skeleton-loader
                type="paragraph"
                v-if="loading"
              ></v-skeleton-loader>
              <p class="text--primary" v-else>
                {{ item.description }}
              </p>
            </v-sheet>
            <v-sheet class="d-flex flex-wrap" color="transparent">
              <v-card width="200" class="ma-2 flex-grow-1" outlined>
                <v-list-item three-line class="ps-0 pe-1">
                  <v-list-item-avatar tile size="90" color="grey" class="my-0">
                    <v-img :src="item.image"> </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="pb-0">
                    <v-skeleton-loader
                      type="paragraph"
                      v-if="loading"
                    ></v-skeleton-loader>
                    <v-list-item-subtitle v-else class="pe-2">
                      This Sub Sub Category has
                      {{ item.total_items_count }} Items
                    </v-list-item-subtitle>

                    <v-list-item-action-text class="text-right" v-if="!loading">
                      <v-btn text small color="primary" @click="viewItems">
                        View all
                      </v-btn>
                    </v-list-item-action-text>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row>
        <v-card outlined class="mt-6 text--secondary" v-if="!loading">
          <v-card-title class="pb-0">
            Actions
            <v-spacer></v-spacer>
          </v-card-title>
          <v-sheet class="d-flex align-center mx-4 my-2 text--secondary">
            Do you want to edit this sub sub category? Make changes to its name,
            description, or settings.
            <v-divider class="ms-2"></v-divider>
            <v-btn color="primary" text depressed @click="editItem()">
              <v-icon left> mdi-pencil </v-icon> Edit
            </v-btn>
          </v-sheet>
          <v-sheet
            class="d-flex align-center mx-4 my-2 text--secondary"
            v-if="item.is_enabled"
          >
            Do you want to disable this sub sub category? It will be hidden from
            users but can be re-enabled later.
            <v-divider class="ms-2"></v-divider>
            <v-btn color="orange" text depressed @click="disableDialog = true">
              <v-icon left> mdi-close </v-icon> disable
            </v-btn>
          </v-sheet>
          <v-sheet class="d-flex align-center mx-4 my-2 text--secondary" v-else>
            Do you want to enable this sub sub category? It will be visible and
            accessible to users.
            <v-divider class="ms-2"></v-divider>
            <v-btn color="blue" depressed text @click="enableDialog = true">
              <v-icon left> mdi-check </v-icon> enable
            </v-btn>
          </v-sheet>
          <v-sheet class="d-flex align-center mx-4 my-2 text--secondary">
            Are you sure you want to delete this sub sub category? This action is
            irreversible.
            <v-divider class="ms-2"></v-divider>
            <v-btn color="error" depressed text @click="deleteDialog = true">
              <v-icon left> mdi-trash-can-outline </v-icon> delete
            </v-btn>
          </v-sheet>
        </v-card>
      </v-card-text>
    </v-card>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this sub sub category? This action cannot
          be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteSubSubCategoryX()"
            :loading="deleteLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="enableDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to enable this sub sub category?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            text
            @click="enableSubSubCategoryX()"
            :loading="enableLoading"
          >
            Enable
          </v-btn>
          <v-btn color="primary" text @click="enableDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="disableDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to disable this sub sub category?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            text
            @click="disableSubSubCategoryX()"
            :loading="disableLoading"
          >
            Disable
          </v-btn>
          <v-btn color="primary" text @click="disableDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import {
  getSubSubCategory,
  deleteSubSubCategory,
  disableSubSubCategory,
  enableSubSubCategory,
} from "@/services/subSubCategoriesService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    dataLoading: false,
    item: {},
    deleteLoading: false,
    deleteDialog: false,
    enableLoading: false,
    enableDialog: false,
    disableLoading: false,
    disableDialog: false,
  }),
  async created() {
    this.getSubSubCategoryX();
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "PageSubSubCategoryList",
        query: { cid: this.item.category_id, scid: this.item.sub_category_id },
      });
    },
    editItem() {
      this.$router.push({
        name: "PageEditSubSubCategory",
        params: { id: this.item.id },
        query: {
          bc: this.item.name,
        },
      });
    },
    async getSubSubCategoryX() {
      this.loading = true;
      let getItemReturn = await getSubSubCategory(this.$route.params.id);
      this.item = getItemReturn;
      this.loading = false;
    },
    async deleteSubSubCategoryX() {
      let data = {
        id: this.item.id,
      };
      this.deleteLoading = true;
      let deleteItemReturn = await deleteSubSubCategory(data);
      this.deleteLoading = false;
      if (deleteItemReturn == "success") {
        this.deleteDialog = false;
        this.goBack();
      }
    },
    async enableSubSubCategoryX() {
      let data = {
        id: this.item.id,
      };
      this.enableLoading = true;
      let enableItemReturn = await enableSubSubCategory(data);
      this.enableLoading = false;
      if (enableItemReturn == "success") {
        this.enableDialog = false;
        this.getSubSubCategoryX();
      }
    },
    async disableSubSubCategoryX() {
      let data = {
        id: this.item.id,
      };
      this.disableLoading = true;
      let disableItemReturn = await disableSubSubCategory(data);
      this.disableLoading = false;
      if (disableItemReturn == "success") {
        this.disableDialog = false;
        this.getSubSubCategoryX();
      }
    },
    viewItems() {
      this.$router.push({
        name: "PageItemList",
        query: { cid: this.item.category_id, scid: this.item.sub_category_id, sscid: this.item.id },
      });
    },
  },
};
</script>