<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined class="mt-7 wraperx">
          <v-card-title class="mb-0 pb-0 primary--text">
            Heros
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="newHeroDialog = true">
              <v-icon left> mdi-plus </v-icon> New Hero
            </v-btn>
          </v-card-title>
          <v-card-title class="primary--text">
            <v-text-field
              dense
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-row class="ma-2" v-if="dataLoadning">
            <v-col cols="12" lg="3" md="4" sm="6" v-for="i in 8" :key="i">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>

          <v-data-iterator
            v-else
            :items="data.data"
            class="ma-4"
            :search.sync="search"
            :items-per-page.sync="limit"
            :page.sync="page"
            :server-items-length="data.totalCount"
            :loading="getHerosLoadning"
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="(item, i) in props.items"
                  :key="i"
                  cols="12"
                  lg="3"
                  md="4"
                  sm="6"
                >
                  <v-card height="100%" outlined class="d-flex flex-column">
                    <v-img height="200" :src="item.image_url"> </v-img>

                    <v-card-title>{{ item.title }}</v-card-title>

                    <v-card-text>
                      <div>
                        {{ item.sub_title }}
                      </div>
                    </v-card-text>
                    <v-spacer></v-spacer>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="
                          statusHerosLoadning && statusHerosId == item.id
                        "
                        @click="updateStatus(item)"
                        rounded
                        depressed
                        x-small
                        dark
                        class="me-2"
                        :color="
                          item.is_enabled ? 'blue lighten-1' : 'red lighten-1'
                        "
                        >{{ item.is_enabled ? "Enabled" : "Disabled" }}</v-btn
                      >
                      <v-btn
                        depressed
                        small
                        icon
                        color="primary"
                        class="me-2 px-0 mt-1"
                        dark
                        @click="
                          imagePreview = item.image_url;
                          selectedHero = item;
                          editHeroDialog = true;
                        "
                      >
                        <v-icon small> mdi-pencil </v-icon>
                      </v-btn>
                      <v-btn
                        depressed
                        small
                        icon
                        color="error"
                        class="mx-0 px-0 mt-1"
                        dark
                        @click="
                          selectedHero = item;
                          deleteHeroDialog = true;
                        "
                      >
                        <v-icon small> mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
    </v-row>

    <!-- New Hero dialog -->
    <v-dialog v-model="newHeroDialog" persistent max-width="960">
      <v-card outlined>
        <v-card-title class="text-h5"> New Hero </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="newHeroForm">
            <v-row>
              <v-col cols="12" md="6">
                <v-file-input
                  flat
                  outlined
                  :rules="[rules.required, rules.fileSize]"
                  accept="image/*"
                  prepend-icon="mdi-camera"
                  label="Select an image"
                  @change="onFileChange"
                  v-model="image"
                  show-size
                ></v-file-input>
                <v-img
                  v-if="imagePreview"
                  :src="imagePreview"
                  max-width="100%"
                  contain
                  class="mb-6"
                ></v-img>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  flat
                  outlined
                  label="Title"
                  v-model="newHero.title"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-textarea
                  flat
                  outlined
                  label="Sub Title"
                  v-model="newHero.sub_title"
                  :rules="[rules.required]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="newHeroDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="addHeroX"
            :loading="newHeroLoading"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Hero dialog -->
    <v-dialog
      v-if="selectedHero"
      v-model="editHeroDialog"
      persistent
      max-width="960"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Edit Hero </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="editHeroForm">
            <v-row>
              <v-col cols="12" md="6">
                <v-file-input
                  flat
                  outlined
                  :rules="[rules.fileSize]"
                  accept="image/*"
                  prepend-icon="mdi-camera"
                  label="Select an image"
                  @change="onFileChange"
                  v-model="image"
                  show-size
                ></v-file-input>
                <v-img
                  v-if="imagePreview"
                  :src="imagePreview"
                  max-width="100%"
                  contain
                  class="mb-6"
                ></v-img>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  flat
                  outlined
                  label="Title"
                  v-model="selectedHero.title"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-textarea
                  flat
                  outlined
                  label="Sub Title"
                  v-model="selectedHero.sub_title"
                  :rules="[rules.required]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="editHeroDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="editHeroX"
            :loading="editHeroLoading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete hero dialog -->
    <v-dialog
      v-model="deleteHeroDialog"
      persistent
      max-width="290"
      v-if="selectedHero"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this hero? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteHeroX(selectedHero.id)"
            :loading="deleteHeroLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteHeroDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { uploadDocument } from "@/services/uploadDocumentsService";
import {
  getAllHeros,
  addHero,
  editHero,
  deleteHero,
  disableHero,
  enableHero,
} from "@/services/herosService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    search: {
      handler() {
        this.getHeros();
      },
      deep: true,
    },
    limit: {
      handler() {
        this.getHeros();
      },
      deep: true,
    },
    page: {
      handler() {
        this.getHeros();
      },
      deep: true,
    },
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
      fileSize: (value) => {
        if (!value) return true;
        return (
          value.size <= 500 * 1024 || "File size must be less than 500 KB."
        );
      },
    },

    search: "",
    page: 1,
    limit: 10,
    data: [],
    dataLoadning: false,
    statusHerosId: null,
    statusHerosLoadning: false,
    getHerosLoadning: false,
    editHeroDialog: false,
    editHeroLoading: false,
    newHeroDialog: false,
    newHeroLoading: false,
    deleteHeroDialog: false,
    deleteHeroLoading: false,
    selectedHero: null,
    newHero: {
      title: "",
      sub_title: "",
      image_url: "",
      is_enabled: true,
    },
    image: null,
    imagePreview: null,
  }),
  async created() {
    this.dataLoadning = true;
    await this.getHeros();
    this.dataLoadning = false;
  },
  mounted() {
    //
  },
  methods: {
    async getHeros() {
      this.getHerosLoadning = true;
      this.data = await getAllHeros(this.page, this.limit, this.search, -1);
      this.getHerosLoadning = false;
    },
    async addHeroX() {
      if (this.$refs.newHeroForm.validate()) {
        this.newHeroLoading = true;
        if (this.image) {
          const formData = new FormData();
          formData.append("file", this.image);
          let upladDocumentReturn = await uploadDocument("hero", formData);
          this.newHero.image_url = upladDocumentReturn.document_url;
        }
        let newHeroReturn = await addHero(this.newHero);
        this.newHeroLoading = false;

        if (newHeroReturn == "success") {
          this.newHeroDialog = false;

          this.$refs.newHeroForm.reset();
          this.getHeros();
        }
      }
    },
    async editHeroX() {
      if (this.$refs.editHeroForm.validate()) {
        this.editHeroLoading = true;
        if (this.image) {
          const formData = new FormData();
          formData.append("file", this.image);
          let upladDocumentReturn = await uploadDocument("hero", formData);
          this.selectedHero.image_url = upladDocumentReturn.document_url;
        }
        let editHeroReturn = await editHero(this.selectedHero);
        this.editHeroLoading = false;

        if (editHeroReturn == "success") {
          this.editHeroDialog = false;

          this.$refs.editHeroForm.reset();
          this.getHeros();
        }
      }
    },
    async deleteHeroX(id) {
      this.deleteHeroLoading = true;
      let deleteHeroReturn = await deleteHero({
        id: id,
      });
      this.deleteHeroLoading = false;

      if (deleteHeroReturn == "success") {
        this.deleteHeroDialog = false;
        this.getHeros();
      }
    },
    async updateStatus(item) {
      this.statusHerosId = item.id;
      this.statusHerosLoadning = true;
      let statusHeroReturn;
      if (item.is_enabled) {
        statusHeroReturn = await disableHero({
          id: item.id,
        });
      } else {
        statusHeroReturn = await enableHero({
          id: item.id,
        });
      }

      this.statusHerosLoadning = false;

      if (statusHeroReturn == "success") {
        this.getHeros();
      }
    },
    onFileChange(file) {
      this.selectedFile = file;
      if (file) {
        // Create a temporary URL to show the image preview
        this.imagePreview = URL.createObjectURL(file);
      } else {
        this.imagePreview = null;
      }
    },
  },
};
</script>