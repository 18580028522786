import store from "../store";
import axiosInstance from "../axiosInstance";

const DATA_API_URL = process.env.VUE_APP_SERVICE_URL + "/api/admin";

const CATEORIES_STORE_NAME = "dashboard";

export const getCounts = async () => {
  if (navigator.onLine) {
    const url = DATA_API_URL + `/v1/dashboard/counts`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${CATEORIES_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    store.commit("setError", { isError: true, error: "You are offline." });
  }
};
