import { render, staticRenderFns } from "./PageViewItem.vue?vue&type=template&id=5b7c074a&scoped=true"
import script from "./PageViewItem.vue?vue&type=script&lang=js"
export * from "./PageViewItem.vue?vue&type=script&lang=js"
import style0 from "./PageViewItem.vue?vue&type=style&index=0&id=5b7c074a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b7c074a",
  null
  
)

export default component.exports