<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined class="mt-7 wraperx">
          <v-card-title class="mb-0 pb-0 primary--text">
            Reviews
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title class="primary--text">
            <v-text-field
              dense
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="reviewsHeaders"
            :items="data.data"
            :search.sync="search"
            :items-per-page.sync="limit"
            :page.sync="page"
            :server-items-length="data.totalCount"
            :loading="getReviewsLoadning"
          >
            <template v-slot:item.is_approved="{ item }">
              <v-btn
                :loading="statusReviewsLoadning && statusReviewsId == item.id"
                @click="updateStatus(item)"
                rounded
                depressed
                x-small
                dark
                :color="item.is_approved ? 'blue lighten-1' : 'red lighten-1'"
                >{{ item.is_approved ? "Approved" : "Rejected" }}</v-btn
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-sheet color="transparent" class="d-flex">
                <v-btn
                  depressed
                  small
                  icon
                  color="error"
                  class="mx-0 px-0 mt-1"
                  dark
                  @click="
                    selectedReview = item;
                    deleteReviewDialog = true;
                  "
                >
                  <v-icon small> mdi-trash-can-outline </v-icon>
                </v-btn>
              </v-sheet>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- Delete review dialog -->
    <v-dialog
      v-model="deleteReviewDialog"
      persistent
      max-width="290"
      v-if="selectedReview"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this review? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteReviewX(selectedReview.id)"
            :loading="deleteReviewLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteReviewDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  getAllReviews,
  deleteReview,
  disableReview,
  enableReview,
} from "@/services/reviewsService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    search: {
      handler() {
        this.getReviews();
      },
      deep: true,
    },
    limit: {
      handler() {
        this.getReviews();
      },
      deep: true,
    },
    page: {
      handler() {
        this.getReviews();
      },
      deep: true,
    },
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },

    reviewsHeaders: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
      },
      {
        text: "Email",
        value: "email",
        sortable: false,
      },
      {
        text: "Rating",
        value: "rating",
        sortable: false,
      },
      {
        text: "Review",
        value: "review",
        sortable: false,
      },
      {
        text: "",
        value: "is_approved",
        sortable: false,
      },
      { text: "", value: "actions", sortable: false },
    ],
    search: "",
    page: 1,
    limit: 10,
    data: [],
    statusReviewsId: null,
    statusReviewsLoadning: false,
    getReviewsLoadning: false,
    deleteReviewDialog: false,
    deleteReviewLoading: false,
    selectedReview: null,
  }),
  created() {
    this.getReviews();
  },
  mounted() {
    //
  },
  methods: {
    async getReviews() {
      this.getReviewsLoadning = true;
      this.data = await getAllReviews(this.page, this.limit, this.search, -1);
      this.getReviewsLoadning = false;
    },
    async deleteReviewX(id) {
      this.deleteReviewLoading = true;
      let deleteReviewReturn = await deleteReview({
        id: id,
      });
      this.deleteReviewLoading = false;

      if (deleteReviewReturn == "success") {
        this.deleteReviewDialog = false;
        this.getReviews();
      }
    },
    async updateStatus(item) {
      this.statusReviewsId = item.id;
      this.statusReviewsLoadning = true;
      let statusReviewReturn;
      if (item.is_approved) {
        statusReviewReturn = await disableReview({
          id: item.id,
        });
      } else {
        statusReviewReturn = await enableReview({
          id: item.id,
        });
      }

      this.statusReviewsLoadning = false;

      if (statusReviewReturn == "success") {
        this.getReviews();
      }
    },
  },
};
</script>