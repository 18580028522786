<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined class="mt-7 wraperx">
          <v-card-title class="mb-0 pb-0 primary--text">
            Enquiries
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title class="primary--text">
            <v-text-field
              dense
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="enquiriesHeaders"
            :items="data.data"
            :search.sync="search"
            :items-per-page.sync="limit"
            :page.sync="page"
            :server-items-length="data.totalCount"
            :loading="getEnquiriesLoadning"
          >
            <template v-slot:item.actions="{ item }">
              <v-sheet color="transparent" class="d-flex">
                <v-btn
                  depressed
                  x-small
                  :color="item.is_read ? 'grey' : 'primary'"
                  class="mx-2 px-1 mt-1"
                  dark
                  @click="
                  readEnquiryX(item)
                  "
                >
                  open
                </v-btn>
                <v-btn
                  depressed
                  small
                  icon
                  color="error"
                  class="mx-0 px-0 mt-1"
                  dark
                  @click="
                    selectedEnquiry = item;
                    deleteEnquiryDialog = true;
                  "
                >
                  <v-icon small> mdi-trash-can-outline </v-icon>
                </v-btn>
              </v-sheet>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- Delete enquiry dialog -->
    <v-dialog
      v-model="deleteEnquiryDialog"
      persistent
      max-width="290"
      v-if="selectedEnquiry"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this enquiry? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteEnquiryX(selectedEnquiry.id)"
            :loading="deleteEnquiryLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteEnquiryDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Read enquiry dialog -->
    <v-dialog
      v-model="readEnquiryDialog"
      persistent
      max-width="480"
      v-if="selectedEnquiry"
    >
      <v-card outlined>
        <v-card-title> {{selectedEnquiry.subject}} </v-card-title>
        <v-card-text>
          <v-sheet>
            {{ selectedEnquiry.name }}
          </v-sheet>
          <v-sheet>
            {{ selectedEnquiry.email }}
          </v-sheet>
          <v-sheet>
            {{ selectedEnquiry.phone }}
          </v-sheet>
          <p class="mt-5">
            {{ selectedEnquiry.message }}
          </p>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="error"
            text
            @click="deleteEnquiryX(selectedEnquiry.id)"
            :loading="deleteEnquiryLoading"
          >
            Delete
          </v-btn> -->
          <v-btn color="primary" text @click="readEnquiryDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  getAllEnquiries,
  deleteEnquiry,
  readEnquiry,
} from "@/services/enquiriesService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    search: {
      handler() {
        this.getEnquiries();
      },
      deep: true,
    },
    limit: {
      handler() {
        this.getEnquiries();
      },
      deep: true,
    },
    page: {
      handler() {
        this.getEnquiries();
      },
      deep: true,
    },
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },

    enquiriesHeaders: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
      },
      {
        text: "Email",
        value: "email",
        sortable: false,
      },
      {
        text: "Phone",
        value: "phone",
        sortable: false,
      },
      {
        text: "Subject",
        value: "subject",
        sortable: false,
      },
      { text: "", value: "actions", sortable: false },
    ],
    search: "",
    page: 1,
    limit: 10,
    data: [],
    getEnquiriesLoadning: false,
    deleteEnquiryDialog: false,
    deleteEnquiryLoading: false,
    selectedEnquiry: null,
    readEnquiryDialog: false,
  }),
  created() {
    this.getEnquiries();
  },
  mounted() {
    //
  },
  methods: {
    async getEnquiries() {
      this.getEnquiriesLoadning = true;
      this.data = await getAllEnquiries(this.page, this.limit, this.search, -1);
      this.getEnquiriesLoadning = false;
    },
    async deleteEnquiryX(id) {
      this.deleteEnquiryLoading = true;
      let deleteEnquiryReturn = await deleteEnquiry({
        id: id,
      });
      this.deleteEnquiryLoading = false;

      if (deleteEnquiryReturn == "success") {
        this.deleteEnquiryDialog = false;
        this.getEnquiries();
      }
    },
    async readEnquiryX(item) {
      this.selectedEnquiry = item;
      this.readEnquiryDialog = true
      if (!item.is_read) {
        let statusEnquiryReturn = await readEnquiry({
          id: item.id,
        });
        if (statusEnquiryReturn == "success") {
          this.getEnquiries();
        }
      }
    },
  },
};
</script>